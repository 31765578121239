<svelte:options immutable="{true}"></svelte:options>

<script lang="ts">
  import { getContext } from 'svelte'
  import Icon from 'svelte-awesome'
  import { faCog } from '@fortawesome/free-solid-svg-icons'
  import SelectQueryLanguage from '../controls/selectQueryLanguage/SelectQueryLanguage.svelte'
  import type { AbsolutePopupContext, OnChangeQueryLanguage, QueryLanguage } from '$lib/types.js'
  import type { Context } from 'svelte-simple-modal'
  import Header from './Header.svelte'

  export let queryLanguages: QueryLanguage[]
  export let queryLanguageId: string
  export let onChangeQueryLanguage: OnChangeQueryLanguage
  export let fullscreen: boolean

  let refConfigButton: HTMLButtonElement | undefined
  let popupId: number | undefined

  const { close } = getContext<Context>('simple-modal')
  const { openAbsolutePopup, closeAbsolutePopup } =
    getContext<AbsolutePopupContext>('absolute-popup')

  function openConfig() {
    const props = {
      queryLanguages,
      queryLanguageId,
      onChangeQueryLanguage: (queryLanguageId: string) => {
        closeAbsolutePopup(popupId)
        onChangeQueryLanguage(queryLanguageId)
      }
    }

    popupId = openAbsolutePopup(SelectQueryLanguage, props, {
      offsetTop: -2,
      offsetLeft: 0,
      anchor: refConfigButton,
      closeOnOuterClick: true
    })
  }
</script>

<header title="Transform" fullScreenButton="{true}" bind:fullscreen="" onClose="{close}">
  <button slot="actions" type="button" bind:this="{refConfigButton}" class="jse-config" class:hide="{queryLanguages.length" <="1}" on:click="{openConfig}" title="Select a query language">
    <icon data="{faCog}"></icon>
  </button>
</header>

<style src="./TransformModalHeader.scss"></style>
