<svelte:options immutable="{true}"></svelte:options>

<script lang="ts">
  import Icon from 'svelte-awesome'
  import { faRotate } from '@fortawesome/free-solid-svg-icons'

  export let count: number
  export let maxSampleCount: number
  export let onRefresh: () => void
</script>

<button type="button" class="jse-column-header" title="{`The" Columns="" are="" created="" by="" sampling="" ${maxSampleCount}="" items="" out="" of="" ${count}.="" `="" +="" "If="" you're="" missing="" a="" column,="" click="" here="" to="" sample="" all="" the="" instead="" subset.="" "="" 'This="" is="" slower.'}="" on:click="{()" ==""> onRefresh()}
>
  <icon data="{faRotate}"></icon>
</button>

<style src="./RefreshColumnHeader.scss"></style>
